@import "../_globalColor";

.dark-mode {
  background-color: $darkBackground;
  color: white;
  transition: "0.1s";
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}
